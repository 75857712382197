import { Icon } from '@iconify/react';
import React from 'react';
import Slider from 'react-slick';
import Div from '../Div';
import Timeline from '../Timeline';
const timelineData = [
  [
    {
      year: '2024',
      name: 'Innovation and Scaling New Heights',
      position: 'Continued to innovate by completing 100+ mobile apps and web platforms, pushing the boundaries of app development across sectors like finance and healthcare.Our mobile app development expertise led to a 150% increase in user adoption and engagement, helping clients grow their digital presence.',
      // type: 'Mobile exelence',
    },
    {
      year: '2023',
      name: 'A Year of Focused Growth – Acquiring OnCab',
      position: 'Acquired OnCab, a ride-hailing app, which significantly boosted our mobile app development portfolio. Focused on software development, especially mobile apps, delivering high-performance solutions tailored to meet client needs in urban mobility, e-commerce, and beyond.',
      // type: 'Desktop exelence',
    },
  ],
  [
    {
      year: '2022',
      name: 'Expanding Our Reach',
      position: 'Delivered more than 50 web and software development projects, creating scalable, high-performance platforms across various industries. Strengthened our app development expertise by building high-quality mobile solutions that helped clients enhance user engagement and improve business processes.',
      // type: 'Desktop exelence',
    },
    {
      year: '2021',
      name: 'Building the Foundation',
      position: 'Launched several successful mobile and web applications, delivering enterprise-grade solutions for clients in retail and healthcare.',
      // type: 'Mobile exelence',
    },
  ],
  // [
  //   {
  //     year: '2022',
  //     name: 'Google awards',
  //     position: 'Website of the day',
  //     type: 'Mobile exelence',
  //   },
  //   {
  //     year: '2021',
  //     name: 'CSS awards design',
  //     position: 'Honorable mention',
  //     type: 'Desktop exelence',
  //   },
  // ],
];

export default function TimelineSlider() {
  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    arrows: false,
    responsive: [
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cs-arrow_style3">
      {timelineData.map((item, index) => (
        <Div key={index} className="p-2">
          <Timeline columnData={item} />
        </Div>
      ))}
    </Slider>
  );
}
