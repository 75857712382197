import React from 'react';
import { useState } from 'react';
import Div from '../Div';
const accordionData = [
  {
    question: 'Do you design illustration website?',
    answer:
      'Sure! Here’s a note you can use to address whether I design illustration websites.  Discussing your vision, target audience, and key features for your illustration website. Helping you draft text for your site, including descriptions, artist statements, and blog posts.',
  },
  {
    question: 'Do you provide design source file after finish work?',
    answer:
      'Here is a note you can use to address whether design source files are provided after completing a project',
  },
  {
    question: 'How to provide project details and payments?',
    answer:
      'We can start with a discussion to understand your vision, goals, and specific requirements for the project. This can be done via email, phone, or video call.  Once we have a clear understanding, you can provide a detailed project brief outlining the scope, deadlines, design preferences, and any specific elements you want included.  I typically require a deposit (e.g., 30-50%) before starting the project, with the balance due upon completion. This ensures commitment from both sides.',
  },
  {
    question: 'Can you tell me please how to contact for project?',
    answer:
      '+92 341 0396 793 Feel free to call or message me for a quicker response or to set up a meeting.',
  },
  {
    question: 'Do you makes custom logo, icon etc?',
    answer:
      'Yes, I specialize in creating custom logos and icons tailored to your brands identity and vision. My services include.   Crafting unique and memorable logos that reflect your brand values and resonate with your target audience.Designing custom icons that enhance your visual communication and fit seamlessly with your overall branding.Offering comprehensive branding packages that may include logos, icons, color palettes, and typography guidelines.',
  },
];

export default function Accordion() {
  const [selected, setSelected] = useState(0);
  const handelToggle = index => {
    if (selected === index) {
      return setSelected(null);
    }
    setSelected(index);
  };

  return (
    <Div className="cs-accordians cs-style1">
      {accordionData.map((item, index) => (
        <Div
          className={`cs-accordian ${selected === index ? 'active' : ''}`}
          key={index}
        >
          <Div
            className="cs-accordian_head"
            onClick={() => handelToggle(index)}
          >
            <h2 className="cs-accordian_title">{item.question}</h2>
            <span className="cs-accordian_toggle cs-accent_color">
              <svg
                width={15}
                height={8}
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
              </svg>
            </span>
          </Div>
          <Div className="cs-accordian_body">
            <Div className="cs-accordian_body_in">{item.answer}</Div>
          </Div>
        </Div>
      ))}
    </Div>
  );
}
