import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  console.log(params)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  let content;
   switch (params.serviceDetailsId) {
    case 'MobileApplication':
      content = (
        <Div className="container">
        <SectionHeading 
          title='Mobile Application' 
          subtitle='Our App Development Services:' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Android App Development'
              subtitle=' Our team crafts feature-rich apps optimized for a wide range of Android devices, ensuring accessibility and engagement for all users.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='iOS App Development'
              subtitle=' We develop intuitive and high-performance applications for iPhone and iPad users, focusing on sleek design and seamless functionality.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Cross-Platform'
              subtitle='Reach a broader audience with apps designed to work flawlessly across multiple platforms, maximizing your market presence and user engagement.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div> 
      )  
      break;
    case 'graphic-design':
      content = (
        <Div className="container">
        <SectionHeading 
          title='Graphic Design' 
          subtitle='Our Graphic Design Services:' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Brand Identity Design'
              subtitle=' We create striking logos and cohesive brand visuals that embody your unique identity and values, ensuring your brand stands out in a competitive marketplace.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Social Media Graphics'
              subtitle='We design eye-catching visuals tailored for social media platforms, enhancing your brand presence and driving engagement in a fast-paced digital environment.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='UI/UX Design'
              subtitle='Our focus on user-centered design ensures that your applications and websites provide an intuitive and enjoyable experience, combining aesthetics with functionality for maximum impact.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div> 
      ) 
     break;
     case 'digital-marketing':
      content = (
        <Div className="container">
        <SectionHeading 
          title='Digital Marketing' 
          subtitle='Our Digital Marketing Services' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Search Engine Optimization (SEO)'
              subtitle='Propel your website to the top of search engine results, attracting organic traffic and enhancing your online visibility. We implement cutting-edge techniques to ensure your brand stands out in a crowded digital landscape.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Pay-Per-Click (PPC)'
              subtitle=' Advertising – Launch impactful ad campaigns that deliver instant visibility and drive qualified traffic to your website. Our targeted approach maximizes your ROI and generates measurable results, helping you achieve your business goals.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Social Media Marketing'
              subtitle='Cultivate a vibrant online community and amplify your brand’s voice across platforms like Facebook, Instagram, and LinkedIn. We create engaging content that resonates with your audience, fostering connections and building brand loyalty.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div> 
      ) 
     break;
     case 'Software-Development':
      content = (
        <Div className="container">
        <SectionHeading 
          title='Software Development' 
          subtitle='Our Software Development Services:' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Custom Software Development'
              subtitle='We develop bespoke software solutions that align perfectly with your business objectives, enhancing operational efficiency and enabling growth.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='Cloud-Based Solutions'
              subtitle='Embrace the power of the cloud with scalable, secure software solutions that optimize your operations and facilitate collaboration among teams.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Mobile App Development'
              subtitle='Engage your audience on the go with intuitive, high-performance mobile applications for both iOS and Android, designed to deliver exceptional user experiences.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div> 
      ) 
     break;
     case 'Web-Development':
      content = (
        <Div className="container">
        <SectionHeading 
          title='Web Development' 
          subtitle='Our Web Development Services' 
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45'/>
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_1.svg'
              title='Custom Website Development'
              subtitle='We design and develop bespoke websites that reflect your brand’s unique personality, ensuring a seamless and captivating user experience.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_2.svg'
              title='E-Commerce Solutions'
              subtitle='Transform your business with robust online stores featuring intuitive navigation, secure payment gateways, and stunning visuals that turn visitors into loyal customers.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/icons/service_icon_3.svg'
              title='Content Management Systems (CMS)'
              subtitle=' Empower yourself with user-friendly CMS solutions that allow you to easily manage and update your content without technical expertise.'
            />
            <Spacing lg='30' md='30'/>
          </Div>
        </Div>
      </Div> 
      ) 
     break;
   
    default:
      break;
   }

  return (
    <>
      <PageHeading 
        title='Service Details'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={params.serviceDetailsId}
      />
      <Spacing lg='145' md='80'/>

        {content}
         {/* <Div className="container">
         <SectionHeading 
           title='Design working process' 
           subtitle='UI/UX Design' 
           variant='cs-style1 text-center'
         />
         <Spacing lg='90' md='45'/>
         <Div className="row">
           <Div className="col-lg-4">
             <IconBox
               icon='/images/icons/service_icon_1.svg'
               title='Sketching'
               subtitle='Sketching is a preliminary design technique that involves quickly drawing out ideas for the layout and functionality of a digital product. This informal approach allows for rapid exploration of concepts.'
             />
             <Spacing lg='30' md='30'/>
           </Div>
           <Div className="col-lg-4">
             <IconBox
               icon='/images/icons/service_icon_2.svg'
               title='Wireframing'
               subtitle='Wireframing is the foundational step in the UI/UX design process that helps visualize the layout and structure of a website or application. It serves as a blueprint, providing a clear outline of the user interface elements and their functionalities.'
             />
             <Spacing lg='30' md='30'/>
           </Div>
           <Div className="col-lg-4">
             <IconBox
               icon='/images/icons/service_icon_3.svg'
               title='Prototyping'
               subtitle='Prototyping involves creating an interactive model of your application or website that simulates user experience. This stage allows you to test functionality and gather feedback before full-scale development begins.'
             />
             <Spacing lg='30' md='30'/>
           </Div>
         </Div>
       </Div> */}

      


      
      <Spacing lg='120' md='50'/>



      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40'/>
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">Below our most design related services</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Web page design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='eCommerce design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Landing page' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Email template' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Application design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Illustration' variant='cs-type2'/>
                <Spacing lg='0' md='10'/>
              </Div>
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Infographic design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Mobile apps design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Banner, brochure, card' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
                <Button btnLink='/service/service-details' btnText='Other design' variant='cs-type2'/>
                <Spacing lg='20' md='10'/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>



      <Spacing lg='150' md='80'/>
      <TestimonialSlider />
      <Spacing lg='145' md='80'/>
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers' 
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion/>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
      <Div className="container">
        <Cta 
          title='Let’s discuss make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
