import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import Div from '../Div';
import PageHeading from '../PageHeading';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function CaseStudyDetailsPage() {
  pageTitle('Case Study Details');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Case Study Details"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Case Study Details"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Importance of storytelling and influencer marketing in social media marketing"
          subtitle="Marketing"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />
        <img
          src="/images/case_study_img_1.jpeg"
          alt="Thumb"
          className="w-100 cs-radius_15"
        />
        <Spacing lg="140" md="80" />
        <h2 className="cs-font_38 text-center">Case Study Overview</h2>
        <Spacing lg="60" md="45" />
        <p className="cs-m0">
        At Alita Code, we take immense pride in our successful projects with incredible clients. As a fully integrated agency, we handle everything from digital marketing and advertising to design and development, packaging, PR, and more—all from one dedicated team. Our case studies reflect our commitment to delivering outstanding results and driving growth for our clients.
        </p>
        <Spacing lg="65" md="45" />
        <Div className="row">
          <Div className="col-sm-6">
            <img
              src="/images/case_study_img_2.jpeg"
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-sm-6">
            <img
              src="/images/case_study_img_3.jpeg"
              alt="Thumb"
              className="w-100 cs-radius_5"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
        <Spacing lg="125" md="55" />
      </Div>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_6">
        <Div className="cs-shape_2"></Div>
        <Div className="cs-shape_2"></Div>
        <Div className="container">
          <Spacing lg="145" md="80" />
          <h2 className="cs-font_38 text-center">Case Study Research</h2>
          <Spacing lg="90" md="45" />
          <Div className="row align-items-center cs-column_reverse_lg">
            <Div className="col-lg-5">
              <h3 className="cs-font_30 cs-m0">Marketing strategy</h3>
              <Spacing lg="45" md="30" />
              <p className="cs-m0">
              At Alita Code, our marketing strategy focuses on creating meaningful connections and driving impactful results. Here’s how we approach it. We begin by deeply understanding our target audience. Through research and data analysis, we identify their needs, preferences, and pain points to tailor our messaging effectively.<br />
                <br />
                Our team crafts engaging and relevant content across multiple platforms. From blog posts and social media updates to videos and infographics, we ensure our content resonates with our audience and reinforces our brand identity.
                We leverage a diverse range of channels—social media, email marketing, SEO, and paid advertising—to maximize our reach and engagement. Each channel is optimized for its specific audience to ensure the best results.
              </p>
            </Div>
            <Div className="col-lg-6 offset-lg-1 text-center">
              <Div className="cs-portfolio_img_in cs-shine_hover_1 rounded-circle">
                <img
                  src="/images/case_study_img_4.png"
                  alt="Case study"
                  className="w-100"
                />
              </Div>
              <Spacing lg="0" md="40" />
            </Div>
          </Div>
          <Spacing lg="100" md="80" />
          <Div className="row align-items-center">
            <Div className="col-lg-6 text-center">
              <Div className="cs-portfolio_img_in cs-shine_hover_1 rounded-circle">
                <img
                  src="/images/case_study_img_5.png"
                  alt="Case study"
                  className="w-100"
                />
              </Div>
              <Spacing lg="0" md="40" />
            </Div>
            <Div className="col-lg-5 offset-lg-1">
              <h3 className="cs-font_30 cs-m0">Branding guideline</h3>
              <Spacing lg="45" md="30" />
              <p className="cs-m0">
                 Our goal is to understand the origin of every error, which is the essence of accusing wrongdoing and praising the truth. We will reveal everything, including those things invented by the truth and the architect of a blessed life, as they are explained. No one experiences this reality without facing its challenges <br />
                <br />
                In most cases, these things arise from misunderstandings or are avoided entirely, but because of the significant pains that come from reasoning through these experiences. It is said that pain exists, and one must understand and appreciate it, but these moments do not occur without labor and suffering.
              </p>
            </Div>
          </Div>
          <Spacing lg="150" md="80" />
        </Div>
      </Div>
      <Spacing lg="140" md="80" />
      <Div className="container text-center">
        <Div className="row col-lg-10 offset-lg-1">
          <h2 className="cs-font_38 cs-m0">Result of The Case Study</h2>
          <Spacing lg="60" md="45" />
          <p className="cs-m0">
          We achieved a 99% increase in user engagement across all digital platforms, 
          resulting in a more active and responsive audience,
          The project resulted in a 99% improvement in conversion rates, directly contributing to increased sales and revenue.
          Our targeted marketing efforts boosted brand awareness by 90% within the target demographic, establishing the client as a leader in their industry.
          Customer satisfaction scores increased significantly, with specific metric showing a 90% rise in positive feedback and reviews.
          </p>
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
